<template>
  <div class="wp-page primacard primacard-list-container" @click="clicked">
    <v-container class="relative">
      <page-title :page="page" />
      <v-row class="py-4">
        <v-col cols="0" md="3" v-if="!$vuetify.breakpoint.mobile">
          <primacard-category />
        </v-col>
        <v-col
          cols="12"
          :md="$vuetify.breakpoint.mobile ? '12' : '9'"
          class="position-relative"
        >
          <div v-if="primacardData">
            <div
              class="primacard-list mb-1"
              v-for="item in primacardData"
              :key="item.id"
            >
              <h1
                v-html="item.title.rendered"
                @click="clicked"
                class="primary--text mb-6"
              ></h1>
              <v-runtime-template
                :template="
                  `<div class='primacard-${$route.params.categoryName}'>${item.content.rendered}</div>`
                "
              />
            </div>
          </div>
          <v-speed-dial
            v-if="$vuetify.breakpoint.mobile"
            v-model="fab"
            :right="true"
            :bottom="true"
            absolute
            direction="left"
            transition="slide-y-reverse-transition"
            class="primacard-filter"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" fab>
                <v-icon v-if="fab">
                  $close
                </v-icon>
                <v-icon v-else>
                  $menu
                </v-icon>
              </v-btn>
            </template>
            <v-card><PrimacardCategory /> </v-card>
          </v-speed-dial>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.primacard-page-subtitle {
  font-size: 1.5rem;
}
.primacard-info-card {
  background: var(--v-green-lighten2) !important;
  .primacard-info-card-title {
    color: var(--v-primary-darken1) !important;
    h3 {
      font-size: 1.3rem !important;
    }
  }
  .primacard-info-card-subtitle {
    color: var(--v-primary-darken1) !important;
    h4 {
      font-size: 1rem !important;
    }
  }
}

.primacard-list-container {
  img {
    max-width: 100%;
  }
  .primacard-title {
    font-size: 13px;
    color: $primary;
  }
  .position-relative {
    position: relative;
  }
  .primacard-panel-header {
    border: 1px var(--v-primary-base) solid;
    padding: 13px !important;
    background: var(--v-green-lighten2);
    h4 {
      font-size: 1rem;
      color: #000000;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 13px !important;
  }
  .primacard-panel-content,
  .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: #000000 !important;
  }
  .primacard-panel-content {
    margin-top: 16px;
  }
  .primacard-list {
    .v-expansion-panel-header {
      color: var(--v-primary-base) !important;
      font-weight: bold;
    }
  }
  .primacard-filter {
    .v-speed-dial__list {
      min-width: 400px;
      left: initial;
      right: 0;
    }
  }
}
</style>
<script>
import PrimacardCategory from "@/components/wordpress/PrimaCardCategory.vue";
import CMSService from "~/service/cmService";
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";
import VRuntimeTemplate from "v-runtime-template";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog
} from "vuetify/lib";

export default {
  name: "PrimaCardList",
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    PrimacardCategory,
    PageTitle
  },
  data() {
    return {
      primacardData: null,
      fab: false
    };
  },
  mixins: [page, clickHandler],
  mounted() {
    this.fetchPrimacardList(this.$route.params.categoryName);
    this.fetchPage("primacard");
  },
  methods: {
    async fetchPrimacardList(categoryName) {
      let res = await CMSService.getCustomPostByFilters(
        "primacard",
        [
          {
            filterName: "filter[categoria_primacard]",
            filterValue: categoryName
          }
        ],
        100
      );

      this.primacardData = res.data;
    }
  },
  watch: {
    "$route.params.categoryName": function(categoryName) {
      this.fetchPrimacardList(categoryName);
    }
  }
};
</script>
